<template>
<section class="header">
   <div class="contain">
      <span class="iconfont iconlogo" style="color=red;"> </span>
      <div class="nav_list">
         <span v-for="value of navList" :key="value.index" @mouseup="toPath(value.path)" :style="navActiveFont(value.index)">
            {{ value.name }}
            <i class="buttom_block" :style="navActiveBlock(value.index)"></i>
         </span>
         <span class="language_change">
            <i :class="$i18n.locale == 'zh' ? 'actived' : ''" @click="i18nZH()">CN</i>
            /
            <i :class="$i18n.locale == 'en' ? 'actived' : ''" @click="i18nEN()">EN</i>
         </span>
      </div>
   </div>
</section>
</template>

<script>
export default {
   inject: ["reload"],
   data() {
      return {
         navList: [{
               index: 0,
               name: this.$t("PC.components.FooterC.ljcr45"),
               path: "Home"
            },
            {
               index: 1,
               name: this.$t("PC.components.FooterC.yl2det"),
               path: "Product"
            },
            {
               index: 2,
               name: this.$t("PC.components.FooterC.av935f"),
               path: "Solution"
            },
                      {
               index: 3,
               name: this.$t("PC.components.FooterC.hyyc12"),
               path: "Agendas"
            },
            {
               index: 4,
               name: this.$t("PC.components.FooterC.yq5vzh"),
               path: "Service"
            },
            {
               index: 5,
               name: this.$t("PC.components.FooterC.3yoxbi"),
               path: "News"
            },
            {
               index: 6,
               name: this.$t("PC.components.FooterC.z4k0gm"),
               path: "About"
            }
         ]
      };
   },
   methods: {
      i18nEN() {
         if (this.$i18n.locale != "en") {
            this.$i18n.locale = "en";
            window.localStorage.setItem("i18n", this.$i18n.locale);
            this.reload();
         }
      },
      i18nZH() {
         if (this.$i18n.locale != "zh") {
            this.$i18n.locale = "zh";
            window.localStorage.setItem("i18n", this.$i18n.locale);
            this.reload();
         }
      },
      toPath(path) {
         this.$router.push({
            name: path
         });
      },
      navActiveFont(index) {
         if (this.$route.meta.index == index) {
            return "color: #003299;";
         }
      },
      navActiveBlock(index) {
         if (this.$route.meta.index == index) {
            return "background: #003299;";
         }
      }
   }
};
</script>

<style lang="scss" scoped>
.header {
   position: fixed;
   z-index: 10;
   top: 0;
   height: 80Px;
   height: 70Px;
   width: 100%;
   box-shadow: 0Px -22Px 10Px 20Px #666;
   background: rgba($color: #fff, $alpha: 1);

   -moz-user-select: none;
   -webkit-user-select: none;
   user-select: none;

   .contain {
      width: 1000Px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70Px;
      color: #003299;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .iconfont.iconlogo {
         font-size: 44Px;
      }

      .nav_list {
         font-size: 16Px;
         font-weight: 600;
         line-height: 22Px;

         span {
            color: #3a3a3a;
            margin-left: 30Px;
            padding: 5Px 0;
            position: relative;

            &:hover {
               cursor: pointer;
            }

            .buttom_block {
               position: absolute;
               left: 0;
               bottom: 0;
               display: inline-block;
               width: calc(100% - 4Px);
               height: 3Px;
               background: transparent;
            }
         }

         .language_change {
            margin-left: 28Px;
            color: #666;
            font-weight: normal;

            i {
               color: #333;
            }

            i.actived {
               font-weight: bold;
               color: #001f5f;
            }
         }
      }
   }
}
</style>
